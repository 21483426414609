.liangnian-main {
  width: 1200px;
  margin: 20px auto;
}
@media only screen and (max-width: 1200px) {
  .liangnian-main {
    width: 95%;
  }
}
.liangnian-wrapper h2 {
  text-align: center;
}
.liangnian-wrapper .ant-row {
  padding-top: 8px;
}
