.about-team .videoWrapper {
  margin-top: -64px;
  height: 565px;
  background: #234;
  z-index: 0;
}
@media only screen and (min-width: 1200px) {
  .about-team .youshi .ant-row-flex {
    width: 90%;
    margin: 0px auto;
    max-width: 1200px;
  }
}
@media only screen and (max-width: 1200px) {
  .about-team .youshi .ant-row-flex {
    width: 100%;
    margin: 0px auto;
  }
}
@media only screen and (max-width: 576px) {
  .about-team .videoitem {
    display: none;
  }
}
.about-team .overlay {
  content: '';
  background: rgba(0, 39, 102, 0.7);
  top: 0;
  left: 0;
  width: 100%;
  height: 565px;
  z-index: 9;
  position: absolute;
}
.about-team .jujue {
  color: #d9d9d9;
  clear: both;
  margin: 180px auto auto;
  text-align: center;
}
.about-team .jujue h1 {
  color: #fafafa;
  font-size: 48px;
}
.about-team .jujue p {
  margin-top: 34px;
}
.about-team .shuju {
  width: 65%;
  margin: 10px auto;
  color: #d9d9d9;
  text-align: center;
}
.about-team .shuju span {
  font-size: 36px;
}
@media only screen and (max-width: 992px) {
  .about-team .jujue .search {
    width: 75%;
  }
  .about-team .shuju {
    width: 75%;
  }
  .about-team .shuju span {
    font-size: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .about-team .jujue {
    margin: 120px auto auto;
  }
  .about-team .jujue h1 {
    font-size: 30px;
  }
  .about-team .jujue p {
    margin-top: 34px;
  }
  .about-team .jujue .search {
    width: 100%;
  }
  .about-team .shuju {
    margin-top: 34px;
    width: 100%;
  }
  .about-team .shuju span {
    font-size: 22px;
  }
}
.about-team .search {
  width: 65%;
  background: rgba(36, 51, 68, 0);
}
.about-team .search input {
  background: rgba(36, 51, 68, 0);
  color: #fafafa;
}
.about-team .search input:hover {
  background: rgba(36, 51, 68, 0.8);
}
.about-team .youshi {
  padding: 10px 0px;
  background: #fafafa;
}
.about-team .youshi .ant-row-flex {
  width: 100%;
  margin: 0px auto;
}
.about-team .backgroundOne {
  padding: 32px 0;
  background: #fafafa;
}
.about-team .backgroundTwo {
  padding: 32px 0;
  background: #fff;
}
.about-team .more {
  color: #000;
}
.about-team .scale {
  overflow: hidden;
  margin: 4px 0;
}
.about-team .shadowLi img {
  transform: scale(1);
  -webkit-transform: scale(1);
  transition: 1s;
  -webkit-transition: 1s;
}
.about-team .shadowLi img:hover {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transition: 1s;
  -webkit-transition: 1s;
  cursor: pointer;
}
.about-team .shadowLi .ant-col-12 {
  box-shadow: 0 0 0 #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-team .shadowLi .ant-col-12:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}
.about-team .shadowLi .ant-col-6 {
  background: transparent;
  box-shadow: 0 0 0 #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-team .shadowLi .ant-col-6:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
}
.about-team .fuwuanlie {
  padding: 50px 0px;
  color: #333;
  background: #f2f2f2;
}
.about-team .fuwuanlie h2 {
  margin-bottom: 20px;
}
.about-team .fuwuanlie .ant-card-head {
  padding: 0;
  margin: 0 24px;
}
.about-team .fuwuanlie .tuanduigengduo {
  padding-top: 10px;
  background: #fff;
}
.about-team .youshicard .ant-card .ant-card-meta-detail {
  text-align: center;
}
@media only screen and (min-width: 1200px) {
  .about-team .fuwuanlie,
  .about-team .tuandui,
  .about-team .shigonggongyi,
  .about-team .zhishitang {
    margin: 0 auto;
    width: 1200px;
  }
}
.about-team .tuandui {
  padding: 50px 0px 0px;
  color: #333;
  min-height: 600px;
  font-size: 14px;
  letter-spacing: 2px;
}
.about-team .tuandui h2 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.about-team .tuandui h3 {
  margin-bottom: 10px;
  text-align: left;
  font-weight: normal;
}
.about-team .tuandui p,
.about-team .tuandui h3 {
  padding-left: 5px;
  padding-right: 5px;
}
.about-team .tuandui .ant-card {
  border: 1px solid rgba(0, 39, 102, 0);
}
.about-team .tuandui .ant-card .ant-card-head {
  border: 0;
}
.about-team .tuandui .ant-card .ant-card-head-title {
  margin: auto;
}
.about-team .tuandui .ant-card .ant-card-meta-detail {
  text-align: center;
  min-height: 74px;
}
.about-team .tuandui .ant-card .ant-card-head-wrapper {
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(0, 39, 102, 0.7);
  border-radius: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
}
.about-team .tuandui .ant-card:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-team .tuandui .ant-card:hover .ant-card-head-wrapper {
  background: rgba(0, 39, 102, 0.7);
}
.about-team .tuandui .ant-card:hover .ant-card-head-wrapper .ant-card-head-title {
  color: #fff;
}
.about-team .tuanduigengduo {
  padding: 30px 0;
  text-align: center;
}
.about-team .tuanduigengduo .ant-btn {
  color: #333;
  border-color: #333;
}
.about-team .tuanduigengduo .ant-btn:hover {
  color: #1890ff;
  border-color: #1890ff;
}
.about-team .shigonggongyi {
  padding: 50px 0;
  color: #333;
  background: #f2f2f2;
}
.about-team .shigonggongyi h2 {
  margin-bottom: 20px;
}
.about-team .shigonggongyi .colTab {
  padding: 5px;
  min-height: 580px;
  background: #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border: 1px solid rgba(0, 39, 102, 0);
  margin: 5px auto;
  max-width: 580px;
}
.about-team .shigonggongyi .colTab:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 39, 102, 0.7);
}
.about-team .zhishitang {
  padding: 50px 0;
  color: #333;
  background: #fafafa;
}
.about-team .zhishitang h2 {
  margin-bottom: 20px;
}
.about-team .fudong:hover {
  /*
  -webkit-box-shadow: 0 0 0 #fff;
  box-shadow: 0 0 0 #fff;
*/
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 39, 102, 0.7);
}
.about-team .fudong {
  margin: 5px auto;
  max-width: 280px;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border: 1px solid rgba(0, 39, 102, 0);
}
.about-team .erji {
  text-align: center;
  font-weight: normal;
}
.about-team .zizhi {
  max-width: 100%;
}
.about-team .zizhiitem {
  min-height: 450px;
}
