.shigonggongyi {
  padding: 0px 0;
  color: #333;
  background: #fff;
}
h2 {
  text-align: center;
  font-size: 30px;
}
.shigonggongyi .ant-steps-item-wait .ant-steps-item-icon {
  border-color: #69c0ff;
  background: #91d5ff;
}
.shigonggongyi .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.shigonggongyi .ant-steps-item-wait .ant-steps-item-title {
  color: #0a5aab;
}
.shigonggongyi .ant-steps-item-wait .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65);
}
.shigonggongyi .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar .ant-tabs-tab {
  text-align: left;
}
.colTab {
  padding: 5px;
  min-height: 580px;
  background: #fff;
  margin: 5px auto;
  max-width: 580px;
}
@media only screen and (max-width: 576px) {
  .colTab {
    min-height: auto;
  }
}
.shigonggongyi h2 {
  margin-bottom: 60px;
}
@media only screen and (min-width: 1200px) {
  .shigonggongyi {
    margin: 0 auto;
    width: 1200px;
  }
}
