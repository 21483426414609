@media only screen and (min-width: 1200px) {
  .team-tuandui {
    margin: 0 auto;
    width: 1200px;
  }
}
.team-tuandui {
  padding: 50px 0px 40px;
  color: #333;
}
.team-tuandui h2 {
  margin-bottom: 20px;
  margin-left: 50px;
  text-align: left;
}
.team-tuandui .ant-card {
  border: 1px solid transparent;
  margin: 5px auto;
  width: 188px;
}
.team-tuandui .ant-card .ant-card-body {
  padding: 12px;
}
.team-tuandui .ant-card .ant-card-cover {
  position: relative;
  width: 186px;
  height: 227px;
  overflow: hidden;
}
.team-tuandui .ant-card .ant-card-meta-detail {
  text-align: center;
}
.team-tuandui .ant-card .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 2px;
}
.team-tuandui .ant-card .ant-card-meta-description {
  font-size: 12px;
}
.team-tuandui .intro {
  color: #333;
  min-height: 436px;
  line-height: 1.8em;
  background: #fff;
  padding: 20px 10px;
  margin: 5px 10px;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border: 1px solid rgba(0, 39, 102, 0);
  letter-spacing: 2px;
}
.team-tuandui .intro p {
  padding-left: 1em;
}
