/* 系统主题颜色 */
.ant-menu-horizontal {
  border-bottom: none;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload.ant-upload-select-picture-card {
  margin: 0 auto !important;
}
.bgBlue {
  background-color: #fff;
}
