.service {
  position: fixed;
  top: 428px;
  visibility: visible;
  opacity: 1;
  transition: 0.2s ease-out;
  width: 50px;
  transform: translateY(-50%);
  left: calc(100vw - 65px);
  font-size: 12px;
  color: #fff;
  z-index: 100;
}
.service a {
  color: #fff;
}
.service .item {
  position: relative;
  box-sizing: border-box;
  width: 50px;
  padding-top: 9px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: lighter;
  color: #000;
  text-align: center;
  cursor: pointer;
  background-color: #cecece;
  z-index: 1;
}
.service .item .anticon {
  font-size: 28px;
  color: #000;
}
.service .item p {
  margin-top: 0.6em;
  margin-bottom: 0;
}
.service .item:hover {
  background-color: #9f9f9f;
}
.service .item.tel {
  border-top-left-radius: 14px;
}
@media only screen and (max-width: 576px) {
  .service {
    display: none;
  }
}
.telpopover {
  font-size: 24px;
}
@media only screen and (max-width: 576px) {
  .right-backup {
    display: none;
  }
}
.right-backup {
  left: calc(100vw - 60px);
  right: auto;
}
.right-backup .ant-back-top-content {
  background-color: #cecece;
}
.right-backup:hover .ant-back-top-content {
  background-color: #9f9f9f;
}
.layer-5#YSF-BTN-HOLDER {
  top: 501px;
  left: calc(100vw - 75px);
  right: auto;
  bottom: auto;
  background-color: #488bff;
  border-radius: 0;
}
.layer-5#YSF-BTN-HOLDER #YSF-CUSTOM-ENTRY-5 {
  background-color: #488bff;
  opacity: 1;
  border-radius: 0;
}
.layer-5#YSF-BTN-HOLDER:hover,
.layer-5#YSF-BTN-HOLDER #YSF-CUSTOM-ENTRY-5:hover {
  background-color: #2e7bff;
  opacity: 1;
}
