.knowledge-bread {
  max-width: 690px;
  margin: 40px auto;
  font-size: 16px;
  line-height: 1.8;
}
.knowledge-bread .markdownContent {
  margin: 5px auto 40px;
  padding: 50px 14px;
  background: #fff;
  box-shadow: 0 0 0 #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}
.knowledge-bread .markdownContent ol > li {
  list-style: decimal;
}
.knowledge-bread .markdownContent img {
  display: flex;
  margin: auto;
  max-width: 100%;
}
.knowledge-bread .markdownContent h1 {
  font-size: 2em;
  text-align: center;
  margin-bottom: 50px;
}
.knowledge-bread .markdownContent h2,
.knowledge-bread .markdownContent h3,
.knowledge-bread .markdownContent h4,
.knowledge-bread .markdownContent h5,
.knowledge-bread .markdownContent h6 {
  text-align: left;
}
