.about-youshi .tuanduiyoushi {
  padding: 50px 0px 0px;
  color: #333;
  min-height: 600px;
  font-size: 14px;
  letter-spacing: 2px;
  margin: auto;
  max-width: 1200px;
}
.about-youshi .tuanduiyoushi .youshicard .ant-card .ant-card-meta-detail {
  text-align: center;
}
.about-youshi .tuanduiyoushi h2 {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.about-youshi .tuanduiyoushi h3 {
  margin-bottom: 10px;
  text-align: left;
  font-weight: normal;
}
.about-youshi .tuanduiyoushi p,
.about-youshi .tuanduiyoushi h3 {
  padding-left: 5px;
  padding-right: 5px;
}
.about-youshi .tuanduiyoushi .ant-card {
  border: 1px solid rgba(0, 39, 102, 0);
}
.about-youshi .tuanduiyoushi .ant-card .ant-card-head {
  border: 0;
}
.about-youshi .tuanduiyoushi .ant-card .ant-card-head-title {
  margin: auto;
}
.about-youshi .tuanduiyoushi .ant-card .ant-card-meta-detail {
  text-align: center;
  min-height: 74px;
}
.about-youshi .tuanduiyoushi .ant-card .ant-card-head-wrapper {
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(0, 39, 102, 0.7);
  border-radius: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
}
.about-youshi .tuanduiyoushi .ant-card:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.about-youshi .tuanduiyoushi .ant-card:hover .ant-card-head-wrapper {
  background: rgba(0, 39, 102, 0.7);
}
.about-youshi .tuanduiyoushi .ant-card:hover .ant-card-head-wrapper .ant-card-head-title {
  color: #fff;
}
