.ant-layout-content.main {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.ant-layout-content.main .ant-menu {
  background: rgba(0, 39, 102, 0.7);
  color: #fff;
}
.dingzhi {
  background: #c2a77e;
  padding: 100px 0 86px;
}
.wrap {
  width: 1200px;
  margin: 0 auto;
}
.wraph2 {
  padding-bottom: 30px;
  color: #fff;
  font-size: 42px;
  line-height: 46px;
  text-align: center;
  font-weight: 400;
}
.wrapp {
  color: #fff;
  text-align: center;
  font-size: 16px;
}
.rowdingzhi {
  margin-top: 65px;
  background: #f1f1f1;
  padding: 12px;
}
