.wrapper {
  background-color: #fff;
  padding-top: 40px;
  overflow: hidden;
}
.line {
  display: block;
  position: relative;
  top: 11px;
  content: '';
  height: 1px;
  margin: 0 auto;
  background: #dbdedf;
}
.title {
  position: relative;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  margin: 0 auto;
  margin-bottom: 30px;
  /*background: #eceff1;*/
  background: transparent;
}
.title .text {
  display: inline-block;
  background: #fff;
}
.title .diamond {
  position: relative;
  top: -5px;
  display: inline-block;
  margin: 0 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  width: 8px;
  height: 8px;
  background: #000;
}
.link {
  display: block;
  position: relative;
  color: #555;
  text-align: center;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border-radius: 4px;
  overflow: hidden;
}
.link .anticon {
  font-size: 48px;
  margin: 12px 0 16px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  will-change: transform;
}
.link .iconbody {
  display: block;
  text-align: center;
  -webkit-transform: scale(0.83);
  -ms-transform: scale(0.83);
  transform: scale(0.83);
  font-family: Lucida Console,Consolas;
  white-space: nowrap;
  font-size: 18px;
}
.link:hover {
  background-color: #1890ff;
  color: #fff;
}
.link:hover .imgspan img {
  filter: drop-shadow(4px 4px 8px #4444dd);
}
.imgspan {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 12px 0 16px 0;
}
