.videoWrapper {
  height: 565px;
  background: #234;
  z-index: 0;
}
.bannerBgVideo {
  min-width: 345px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/static/images/index/1440x480-index-1.jpg);
  overflow: hidden;
}
.bannerBgVideo .index-bg1 {
  height: 565px;
  background-image: url(https://public.qzwy.com/static/images/index/1440x480-index-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.bannerBgVideo .index-bg2 {
  height: 565px;
  background-image: url(/static/images/index/1440x480-index-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.bannerBgVideo .index-bg3 {
  height: 565px;
  background-image: url(/static/images/index/1440x480-index-3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (max-width: 576px) {
  .videoitem-bg {
    display: none;
  }
}
.overlay {
  content: '';
  background: rgba(58, 96, 134, 0.5);
  top: 64px;
  left: 0;
  min-width: 345px;
  width: 100%;
  height: 565px;
  z-index: 9;
  position: absolute;
}
@media screen and (max-width: 576px) {
  .jujue {
    min-width: 345px;
    color: #d9d9d9;
    clear: both;
    margin: 50px 3px auto;
    text-align: center;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .jujue {
    max-width: 576px;
    color: #d9d9d9;
    clear: both;
    margin: 180px auto auto;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .jujue {
    max-width: 768px;
    color: #d9d9d9;
    clear: both;
    margin: 180px auto auto;
    text-align: center;
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .jujue {
    max-width: 992px;
    color: #d9d9d9;
    clear: both;
    margin: 180px auto auto;
    text-align: center;
  }
}
@media screen and (min-width: 1200px) {
  .jujue {
    max-width: 1200px;
    color: #d9d9d9;
    clear: both;
    margin: 180px auto auto;
    text-align: center;
  }
}
.jujue h1 {
  max-width: 800px;
  margin: auto;
  color: #fafafa;
  font-size: 66px;
  letter-spacing: 3px;
  text-align: justify;
}
.jujue h1 span.left {
  display: inline-block;
  width: 50%;
  text-align: left;
}
.jujue h1 span.right {
  display: inline-block;
  width: 50%;
  text-align: right;
}
.jujue .fuwukouhao {
  font-size: 20px;
  letter-spacing: 4px;
  margin-top: 30px;
}
.search {
  max-width: 800px;
  background: rgba(36, 51, 68, 0);
}
.search input {
  background: rgba(36, 51, 68, 0);
  color: #fafafa;
  height: 48px;
}
.search input:hover {
  background: rgba(36, 51, 68, 0.8);
}
.search button {
  height: 48px;
}
.shuju {
  max-width: 800px;
  margin: 10px auto;
  color: #d9d9d9;
  text-align: center;
}
.shuju a {
  color: #d9d9d9;
  font-size: 20px;
}
.shuju span {
  color: #fadb14;
}
@media screen and (max-width: 576px) {
  .videoWrapper,
  .bannerBgVideo .index-bg1,
  .bannerBgVideo .index-bg2,
  .bannerBgVideo .index-bg3,
  .overlay {
    height: 365px;
  }
  .bannerBgVideo .index-bg1 {
    background-image: url(/static/images/index/750x730-index-1.jpg);
  }
  .bannerBgVideo .index-bg2 {
    background-image: url(/static/images/index/750x730-index-2.jpg);
  }
  .bannerBgVideo .index-bg3 {
    background-image: url(/static/images/index/750x730-index-3.jpg);
  }
  .jujue h1 {
    font-size: 30px;
  }
  .jujue .fuwukouhao {
    font-size: 16px;
    letter-spacing: 7px;
    padding: 14px;
  }
  .shuju a {
    font-size: 16px;
  }
}
