.wrapper {
  background-color: #fff;
  padding-top: 40px;
  overflow: hidden;
}
.line {
  display: block;
  position: relative;
  top: 11px;
  content: '';
  height: 1px;
  margin: 0 auto;
  background: #dbdedf;
}
.title {
  position: relative;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  margin: 0 auto;
  margin-bottom: 30px;
  /*background: #fafafa;*/
  background: transparent;
}
.title .text {
  display: inline-block;
  background: #fff;
}
.title .diamond {
  position: relative;
  top: -5px;
  display: inline-block;
  margin: 0 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  width: 8px;
  height: 8px;
  background: #000;
}
.box a {
  display: block;
  position: relative;
}
.kjdz-itembg {
  width: 100%;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.mask .kjdz-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #161a1d;
  opacity: 0;
  filter: alpha(opacity=60);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.content {
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  bottom: 0;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.itemimgpanel {
  position: relative;
  width: 25%;
  height: 54px;
  display: inline-block;
}
.itemimg,
.itemimghover {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: inline-block;
  opacity: 1;
  filter: alpha(opacity=100);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.itemimghover {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.linepanel {
  padding: 0;
  margin: 0;
  font-size: 0;
  line-height: 0;
  margin-top: 20px;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.itemline {
  width: 20px;
  height: 2px;
  background: #fff;
  display: inline-block;
  opacity: 1;
  filter: alpha(opacity=100);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.itemtitle {
  color: #fff;
  font-size: 22px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  margin-top: 24px;
  font-weight: 700;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.itemdesc,
.itemlink {
  opacity: 0;
  filter: alpha(opacity=0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.itemdesc {
  font-size: 14px;
  color: #fff;
  text-align: center;
  margin-top: 30px;
  padding: 0 23px;
  line-height: 24px;
  height: 0px;
}
.itemlink {
  display: inline-block;
  border: 1px solid #fff;
  width: 120px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  margin-top: 62px;
}
.list {
  display: block;
  position: relative;
  height: 420px;
  text-align: center;
}
.list:hover .kjdz-bg {
  background: rgba(0, 0, 0, 0.6);
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.list:hover .content {
  top: 13%;
}
.list:hover .itemimg {
  opacity: 0;
  filter: alpha(opacity=0);
}
.list:hover .itemimghover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.list:hover .linepanel {
  margin-top: 10px;
}
.list:hover .itemline {
  opacity: 0;
  filter: alpha(opacity=0);
}
.list:hover .itemtitle {
  margin-top: 12px;
}
.list:hover .itemdesc,
.list:hover .itemlink {
  opacity: 1;
  filter: alpha(opacity=100);
}
.list:hover .itemdesc {
  margin-top: 22px;
}
.list:hover .itemlink {
  margin-top: 14%;
}
.itemlink:hover {
  color: #00c1de;
  background: #fff;
}
