@media only screen and (min-width: 1200px) {
  .projects-tuandui {
    margin: 0 auto;
    width: 1200px;
  }
}
.projects-tuandui {
  padding: 50px 0px 0px;
  color: #333;
}
.projects-tuandui h2 {
  margin-bottom: 20px;
  margin-left: 10px;
  text-align: left;
}
.projects-tuandui .intro {
  color: #333;
  line-height: 1.8em;
  min-height: 300px;
  background: #fff;
  padding: 20px 10px 10px;
  margin: 5px 0;
  border: 1px solid rgba(0, 39, 102, 0);
}
.projects-tuandui .intro p {
  letter-spacing: 2px;
  font-size: 16px;
}
.projects-tuandui .intro .ant-card {
  margin: 5px auto;
  width: 186px;
}
.projects-tuandui .intro .ant-card .ant-card-body {
  padding: 12px;
}
.projects-tuandui .intro .ant-card .ant-card-cover {
  width: 186px;
  height: 227px;
  overflow: hidden;
}
.projects-tuandui .intro .ant-card .ant-card-meta-detail {
  text-align: center;
}
.projects-tuandui .intro .ant-card .ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 2px;
}
.projects-tuandui .intro .ant-card .ant-card-meta-description {
  font-size: 12px;
}
.projects-tuandui .intro .ant-card:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .projects-tuandui .intro p {
    max-width: 480px;
  }
}
@media only screen and (min-width: 1200px) {
  .projects-tuandui .intro p {
    max-width: 600px;
  }
}
.anliimg {
  width: 100%;
  padding: 20px 0;
}
