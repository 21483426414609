.submenu-wrapper {
  background: rgba(10, 90, 171, 0.7);
}
.submenu-wrapper .affMenu {
  max-width: 1200px;
  margin: auto;
  background: transparent;
  color: #fff;
  border-bottom-color: transparent;
}
.submenu-wrapper .affMenu li.ant-menu-item a {
  color: #fff;
}
.submenu-wrapper .affMenu li.ant-menu-item-selected a {
  color: #1f1f1f;
}
.submenu-wrapper .affMenu a {
  color: #fff;
}
.submenu-wrapper .affMenu a:hover {
  color: #1f1f1f;
}
