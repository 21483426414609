.office-header-wrapper {
  position: relative;
  height: 565px;
  /*background: #005bc2;*/
  background-image: linear-gradient(-44deg, #0a5aab, #005bc2 79%, #0038dc);
  overflow: hidden;
}
.office-header-wrapper .row {
  margin: 0 auto;
  max-width: 1200px;
  height: 565px;
}
.office-header-wrapper .head {
  float: left;
  margin-left: 10px;
  max-width: 590px;
  padding-top: 215px;
}
.office-header-wrapper .head .headtitle {
  margin-bottom: 10px;
  font-size: 36px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 50px;
}
.office-header-wrapper .head .headsplitter {
  margin-bottom: 10px;
  width: 40px;
  height: 4px;
  background: #00c1de;
}
.office-header-wrapper .head .headmainDescription {
  max-width: 564px;
  height: 30px;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.13px;
  line-height: 30px;
}
.office-header-wrapper .head .headsubDescription {
  max-width: 564px;
  height: 30px;
  margin-bottom: 40px;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.13px;
  line-height: 30px;
}
.office-header-wrapper .head .headbutton {
  display: inline-block;
  width: 160px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
.office-header-wrapper .head .headbutton:hover {
  color: #00c1de;
  background: #fff;
  border: 1px solid #fff;
}
.buttonBox {
  display: none;
}
@media screen and (max-width: 576px) {
  .office-header-wrapper {
    height: 365px;
  }
}
