.wrapper {
  background-color: #fff;
  padding-top: 40px;
  overflow: hidden;
}
.line {
  display: block;
  position: relative;
  top: 11px;
  content: '';
  height: 1px;
  margin: 0 auto;
  background: #dbdedf;
}
.title {
  position: relative;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  margin: 0 auto;
  margin-bottom: 30px;
  /*background: #eceff1;*/
  background: transparent;
}
.title .text {
  display: inline-block;
  background: #fff;
}
.title .diamond {
  position: relative;
  top: -5px;
  display: inline-block;
  margin: 0 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  width: 8px;
  height: 8px;
  background: #000;
}
.gengduo {
  padding: 30px 0;
  text-align: center;
}
.gengduo .ant-btn {
  color: #333;
  border-color: #333;
}
.gengduo .ant-btn:hover {
  color: #1890ff;
  border-color: #1890ff;
}
.link {
  display: block;
  position: relative;
}
.link .cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  background: rgba(9, 109, 217, 0);
  transition: background 0.3s;
}
.link .leftLine {
  width: 8px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  opacity: 0;
}
.link .rightLine {
  width: 8px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
}
.link .play {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -35%;
  top: 0;
  opacity: 0;
  font-size: 16px;
  color: #fff;
}
.link:hover .cover {
  background: rgba(9, 109, 217, 0.5);
}
.link:hover .leftLine {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 1;
  left: 10%;
}
.link:hover .rightLine {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 1;
  right: 10%;
}
.link:hover .play {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 1;
}
