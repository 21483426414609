@media only screen and (min-width: 1200px) {
  .knowledge-main {
    margin: 0 auto;
    width: 1200px;
  }
}
.knowledge-main {
  padding: 50px 0;
  color: #333;
}
.knowledge-main .daohang {
  font-size: 16px;
}
.knowledge-main .knowledge-content {
  margin-top: 40px;
  margin-bottom: 15px;
  background: #fff;
  padding: 50px 14px 20px;
  box-shadow: 0 0 0 #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}
.knowledge-main .knowledge-content h3 {
  margin-bottom: 1em;
}
.knowledge-main .knowledge-content a {
  color: #000;
}
.knowledge-main .knowledge-content a:hover {
  color: #40a9ff;
}
.knowledge-main .knowledge-content .ant-list-vertical .ant-list-item-content {
  margin-bottom: 0px;
}
.knowledge-main .sider {
  margin-top: 40px;
  margin-bottom: 20px;
  background: #fff;
  padding: 50px 14px 14px;
  box-shadow: 0 0 0 #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border-radius: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}
.knowledge-main .sider h3 {
  margin-bottom: 2em;
}
.knowledge-main .sider a {
  display: inline-block;
  background: #002766;
  color: #fff;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 5px;
}
.knowledge-main .sider a:hover {
  background: #fff;
  color: #002766;
  border: 1px solid #002766;
}
