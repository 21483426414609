img.imgItem {
  transition: all 0.3s ease-in-out;
}
img.imgItem:hover {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15) !important;
}
.imghover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  padding: 10px 10px 8px;
  opacity: 0;
  transition: 0.25s;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0, rgba(0, 0, 0, 0) 100%);
  font-size: 14px;
  color: #fff;
}
.imghover p {
  text-align: center;
  margin-bottom: 5px;
}
.itemlist {
  position: relative;
}
.itemlist:hover .imghover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
