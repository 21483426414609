.ant-layout {
  background: #fff;
}
.research-main {
  max-width: 1200px;
  margin: 24px auto 14px;
}
.research-main .ant-menu {
  background: rgba(10, 90, 171, 0.7);
  color: #fff;
}
.research-main .ant-menu li {
  color: #fff;
}
.research-main .ant-menu li a {
  color: #fff;
}
.research-main .ant-menu li.ant-menu-item-selected {
  color: #1f1f1f;
}
.research-main .ant-menu li.ant-menu-item-selected a {
  color: #1f1f1f;
}
.research-main .ant-menu li:hover {
  color: #1f1f1f;
}
.research-main .ant-menu li:hover a {
  color: #1f1f1f;
}
.research-main .side {
  background: transparent;
  min-width: 200px;
  z-index: 0;
}
@media only screen and (max-width: 576px) {
  .research-main .headerImgDiv {
    display: none;
  }
  .research-main .research-content img {
    display: none;
  }
  .research-main .research-content .rowOne {
    padding: 10px;
  }
}
.research-main .research-content {
  margin: 0 0 0 24px;
  min-height: 280px;
}
.research-main .rowOne {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px 60px;
  background: #bae7ff;
  box-shadow: 0 0 0 #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}
.research-main .rowOne img {
  width: 100%;
  padding: 5px;
}
.research-main .rowOne p {
  line-height: 2;
  font-size: 16px;
  padding: 5px;
}
.research-main .rowOne:hover {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate3d(0, -2px, 0);
  transform: translate3d(0, -2px, 0);
  border-radius: 2px;
}
.research-main .rowOne.secondColor {
  background: #e6f7ff;
}
@media only screen and (max-width: 576px) {
  .research-main .rowOne:hover {
    -webkit-transform: none;
    transform: none;
  }
}
