.footer.ant-layout-footer {
  z-index: 100;
  text-align: center;
  background-color: #e9e9e9;
  color: #434343;
}
.footer-bottom,
.footer-bottom a {
  color: #8c8c8c;
}
.footer-bottom {
  border-top: 1px solid #8c8c8c;
  padding: 21px 0;
  line-height: 22px;
  text-align: right;
  max-width: 1200px;
  margin: auto;
}
.beian {
  text-align: left;
}
.copyright {
  text-align: right;
}
@media only screen and (max-width: 576px) {
  .beian,
  .copyright {
    text-align: center;
  }
}
.footer-wrap {
  padding: 72px 0;
  max-width: 1200px;
  margin: auto;
}
@media only screen and (max-width: 576px) {
  .footer-wrap {
    display: none;
  }
}
.footer-item {
  display: inline-block;
  text-align: left;
}
.footer-item h2 {
  margin-bottom: 24px;
  color: #434343;
  font-size: 16px;
}
.footer-item div {
  margin-bottom: 12px;
  font-size: 14px;
}
.footer-item div a {
  color: #8c8c8c;
}
