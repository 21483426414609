.bantoumingtopnav {
  background: #e9e9e9;
  z-index: 10;
}
.topnav {
  z-index: 10;
}
.header.ant-layout-header {
  z-index: 100;
  max-width: 1200px;
  margin: auto;
  padding: 0;
  background: #cecece;
}
.header-touming.ant-layout-header {
  z-index: 100;
  max-width: 1200px;
  margin: auto;
  padding: 0;
  background: rgba(0, 0, 0, 0);
}
.header-bantouming.ant-layout-header {
  z-index: 100;
  max-width: 1200px;
  margin: auto;
  padding: 0;
  background: transparent;
}
#logo {
  overflow: hidden;
  padding-left: 0;
  float: left;
  height: 64px;
  line-height: 64px;
  text-decoration: none;
  white-space: nowrap;
}
#logo img {
  vertical-align: middle;
  line-height: 64px;
  height: 64px;
  margin-right: 8px;
}
#logo h1 {
  display: none;
  vertical-align: middle;
  font-size: 30px;
  color: #fff;
}
.headerRight {
  height: 64px;
  line-height: 64px;
}
.user {
  height: 64px;
  line-height: 64px;
  float: right;
  margin-right: 40px;
  margin-left: 16px;
}
.headerLoginButton span {
  color: #fff;
}
ul.nav {
  border: 0;
  float: right;
  color: #000;
  background: transparent;
  border-bottom: none;
  /*
  .ant-menu-item:hover::after {
    border-top: 4px solid #1890ff;
    border-bottom: 4px solid transparent;
  }
  .ant-menu-item-selected, .ant-menu-item-active {
    border-top: 4px solid #1890ff;
    border-bottom: 4px solid transparent;
  }
  .ant-menu-item-selected::after, .ant-menu-item-active::after {
    border-bottom: 0 !important;
  }
  */
}
ul.nav .ant-menu-item {
  height: 64px;
  line-height: 64px;
  min-width: 52px;
  /*border-top: 4px solid transparent;*/
}
ul.nav .ant-menu-item a {
  color: #000;
}
.navIcon.anticon {
  display: none;
  position: absolute;
  right: 55px;
  top: 16px;
  z-index: 10;
  width: 36px;
  height: 36px;
  cursor: pointer;
  color: #0a5aab;
  font-size: 34px;
}
@media only screen and (max-width: 991px) {
  .navIcon.anticon {
    display: block;
  }
  .nav-pop.ant-popover .ant-popover-inner-content {
    padding: 0;
  }
  .nav-pop.ant-popover li:after {
    border-right: 0px solid transparent;
  }
  .nav-pop.ant-popover li {
    border-right: 8px solid transparent;
  }
}
@media only screen and (max-width: 576px) {
  #logo {
    padding-left: 8px;
  }
  #logo img {
    height: 44px;
  }
  #logo h1 {
    font-size: 22px;
  }
  .navIcon.anticon {
    right: 18px;
  }
}
.navWrapper {
  text-align: center;
  padding-bottom: 5px;
}
.yidongduan {
  margin-right: 7px;
}
@media only screen and (min-width: 1200px) {
  ul.nav {
    min-width: 950px;
    text-align: right;
  }
}
