.anlidata-box {
  margin-bottom: 40px;
}
.anlidata-box .ant-list-grid .ant-col > .ant-list-item {
  margin-bottom: 0;
}
.anlidata-box .link {
  display: block;
  position: relative;
}
.anlidata-box .link .cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  background: rgba(9, 109, 217, 0);
  transition: background 0.3s;
}
.anlidata-box .link .leftLine {
  width: 8px;
  height: 2px;
  background: #fff;
  position: absolute;
  left: 0;
  top: 50%;
  opacity: 0;
}
.anlidata-box .link .rightLine {
  width: 8px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
}
.anlidata-box .link .play {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -35%;
  top: 0;
  opacity: 0;
  font-size: 16px;
  color: #fff;
}
.anlidata-box .link:hover .cover {
  background: rgba(9, 109, 217, 0.5);
}
.anlidata-box .link:hover .leftLine {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 1;
  left: 10%;
}
.anlidata-box .link:hover .rightLine {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 1;
  right: 10%;
}
.anlidata-box .link:hover .play {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  opacity: 1;
}
