.fuwuanli-team .main .tuandui {
  padding: 50px 0px 0px;
  color: #333;
}
.fuwuanli-team .main .tuandui ul {
  text-align: center;
  margin-bottom: 1em;
}
.fuwuanli-team .main .tuandui ul li {
  list-style: none;
  display: inline-block;
  margin: 0 20px;
  padding-left: 2px;
  letter-spacing: 2px;
  font-size: 16px;
}
.fuwuanli-team .main .tuandui ul li.selected {
  color: #fff;
  background: #0a5aab;
}
.fuwuanli-team .main .tuandui ul li:hover {
  background: #0a5aab;
}
.fuwuanli-team .main .tuandui ul li:hover a {
  color: #fff;
}
.fuwuanli-team .main .tuandui ul li a {
  color: #333;
}
@media only screen and (min-width: 1200px) {
  .fuwuanli-team .main .tuandui {
    margin: 0 auto;
    width: 1200px;
  }
}
