.contentWrapOffice {
  margin: auto auto 40px auto;
}
.wrapper1200px {
  max-width: 1200px;
  margin: 0 auto;
}
.wrapper1400px {
  max-width: 1400px;
  margin: 0 auto;
}
