.main-container {
  display: flex;
  justify-content: center;
}
.topic-pagenation-container {
  display: flex;
  margin: 30px 0;
  justify-content: center;
}
.main-inside-container {
  max-width: 1100px;
  padding: 0 15px;
  width: 100%;
  /* height: 2000px; */
  /* background-color: aqua */
}
.menu-group-left {
  display: none;
}
.ant-drawer-body {
  padding: 0 !important;
}
/* Home Part CSS */
.home-container::after {
  display: block;
  content: '';
  clear: both;
}
.home-container .list-item-container {
  width: 100%;
  float: left;
  padding: 10px 0;
  max-width: 740px;
  background: #ffffff;
}
.home-container .list-item-container .topic-channel-button {
  margin-right: 5px;
  margin-bottom: 5px;
  height: 28px;
  font-size: 14px;
}
.home-container .home-right-container {
  margin-top: 30px;
  width: 300px;
  display: flex;
  float: right;
  flex-direction: column;
}
.home-container .home-right-container .user-info-container {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid #dddddd;
}
.home-container .home-right-container .user-info-container--right {
  display: flex;
  flex-direction: column;
}
.home-container .home-right-container .hot-topic-container {
  margin-top: 20px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dddddd;
}
.home-container .home-right-container .hot-topic-container .hot-title-container {
  color: #52c41a;
  display: flex;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
}
.home-container .home-right-container .hot-topic-container .hot-title-container .title-start-block {
  height: 26px;
  width: 5px;
  background-color: #52c41a;
  margin-right: 10px;
}
.home-container .home-right-container .hot-topic-container .hot-list-container {
  list-style: none;
  padding-left: 15px;
}
.home-container .home-right-container .ad-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container .topic-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.home-container .topic-container .left-item {
  margin-right: 15px;
}
.home-container .topic-container .right-item {
  max-width: 72%;
  display: flex;
  flex-direction: column;
}
.home-container .topic-container .right-item h1 {
  font-size: 18px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.home-container .topic-container .right-item .desc {
  color: #979797;
  font-size: 16px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.home-container .topic-container .right-item .bottom-info {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.home-container .topic-container .right-item .bottom-info .bottom-tag {
  border-radius: 3px;
}
.home-container .topic-container .right-item .bottom-info .info-item {
  margin: 0 5px;
}
.home-container .topic-container .comment-info-container {
  position: absolute;
  right: 0;
  border-radius: 25px;
  padding: 0 15px;
  height: 25px;
  line-height: 25px;
  background-color: #dddddd;
  color: #ffffff;
}
/* TopicDetail Part CSS */
.topic-detail-container .detail-title {
  margin: 30px 0;
  font-size: 46px;
  word-wrap: break-word;
}
.topic-detail-container .main-info-container {
  display: flex;
  position: relative;
}
.topic-detail-container .main-info-container .user-avatar {
  margin-right: 10px;
}
.topic-detail-container .main-info-container .praise-control-container {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}
.topic-detail-container .main-control-container {
  padding-left: 60px;
}
.topic-detail-container .topic-content-container {
  font-size: 18px;
  color: black;
  word-wrap: break-word;
}
.topic-detail-container .comment-list {
  margin-top: 20px;
}
/* TopicEdit Part CSS */
.editer-container {
  width: 740px;
  margin-top: 40px !important;
}
@media screen and (max-width: 768px) {
  .home-container .topic-container .right-item .bottom-info .hide-item {
    display: none;
  }
  .menu-group-left {
    display: block;
  }
}
@media screen and (max-width: 1100px) {
  .home-container .home-right-container {
    display: none;
  }
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
